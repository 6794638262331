import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';

export default function DisplayError(message) {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        if (message)
            setOpen(true);
    }, [message])
    return (
        <div >
            <Dialog id="errorBox" open={open}>
                < DialogTitle >
                    Lỗi
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <Button onClick={handleClose} color="primary" >
                    Đóng
                </Button>
            </Dialog>
        </div >
    );
}