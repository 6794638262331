import React, { useState, useEffect } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import "./DisplayChangeForm.css"

const ChangeForm = (
  oldRouteIndex,
  oldIndex,
  moveToSpecificRoute,
  state,
  setState
) => {
  const [open, setOpen] = useState(false);
  const [newRouteIndex, setNewRouteIndex] = useState();
  const [newIndex, setNewIndex] = useState();

  const handleClose = () => {
    setOpen(false);
    setState(false);
  };

  useEffect(() => {
    setOpen(state);
  }, [state]);

  return (
    <div>
      <Dialog id="changeForm" open={open}>
        <DialogTitle>Nhập tuyến muốn chuyển đến</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleClose();
              moveToSpecificRoute(
                oldRouteIndex,
                oldIndex,
                newRouteIndex
              );
            }}
          >
            <div className="form-group row mb-3">
              <label htmlFor="newRoute" className="col-sm-4 col-form-label">
                Mã tuyến
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="newRoute"
                  required
                  onChange={(event) => {
                    setNewRouteIndex(Number(event.target.value));
                  }}
                />
              </div>
            </div>
            <button
              type="submit"
              id="filter-reset"
              className="btn btn-primary float-end mb-2"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              Chuyển
            </button>
            <button
              onClick={handleClose}
              color="primary"
              type="button"
              id="cancel-button"
              className="btn btn-secondary float-end mb-2"
            >
              Huỷ
            </button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ChangeForm;
