import {
  Switch, Route, Redirect, BrowserRouter as Router
} from 'react-router-dom'
import GetInfo from './GetInfo/GetInfo';
import DisplayRoute from './DisplayRoute/DisplayRoute'
import ArrangeVehicle from './ArrangeVehicle/ArrangeVehicle'
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <GetInfo />
          </Route>
          <Route path="/route/:id" component={DisplayRoute} />
          <Route path="/route">
            <Redirect to="/" />
          </Route>
          <Route path="/vehicle" component={ArrangeVehicle} />
        </Switch>
      </Router>

    </div>
  );
}

export default App;
