import React, { useState, useEffect } from "react";
import DisplayChangeForm from "../DisplayChangeForm/DisplayChangeForm";
import transitions from "bootstrap";
import { Collapse } from "bootstrap"
import "./DisplayPlan.css";
export default function DisplayPlan({
  idx,
  route,
  moveToDraftRoute,
  moveToSpecificRoute,
  viewOnMap,
  planID,
}) {
  const [oldRouteIndex, setOldRouteIndex] = useState();
  const [oldIndex, setOldIndex] = useState();
  const [state, setState] = useState(false);
  const getTotalVolume = (route) => {
    let totalVolume = 0;
    route.detail.forEach((o) => {
      totalVolume += o.unload;
    });
    return totalVolume;
  };
  const [districtList, setDistrictList] = useState([]);
  useEffect(() => {
    let a = []
    route.detail.map((shipment, index) => (
      shipment.longitude === null ? (moveToDraftRoute(idx, index)) : (shipment),
      a.indexOf(shipment.district) === -1 ? (shipment.district !== undefined ? a.push(shipment.district) : a) : a
    ))
    setDistrictList(a)
  }, [])

  const displayDistrictList = () => {
    let disList = ""
    districtList.forEach((district) => {
      disList += district + ", "
    })
    return disList
  }
  return (
    <div>
      {DisplayChangeForm(
        oldRouteIndex,
        oldIndex,
        moveToSpecificRoute,
        state,
        setState
      )}
      <div className="accodion ">
        <div className="accodion-item border-secondary">
          <h2 className="accodion-header">
            {idx === 0 ? (
              <div className="card-header accodion-button"
                type="button" data-bs-toggle="collapse" aria-expanded="true"
                data-bs-target={"#collapseHead" + idx + "-" + planID}
                aria-controls={"#collapseHead" + idx + "-" + planID}
                id={"Header" + idx + "-" + planID}>
                <div className="d-flex justify-content-between">
                  <h5 className="card-title">Chưa phân tuyến</h5>
                </div>
              </div>
            ) : (
              <div className="card-header accodion-button"
                type="button" data-bs-toggle="collapse" aria-expanded="false"
                data-bs-target={"#collapseHead" + idx + "-" + planID}
                aria-controls={"#collapseHead" + idx + "-" + planID}
                id={"Header" + idx + "-" + planID}>

                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="card-title">Tuyến #{idx}</h5>
                  </div>
                  <div className="">
                    <div className="card_text text-end">
                      Tổng quãng đường: {Math.round(route?.distance)}m
                    </div>
                    <div className="card_text text-end">
                      Tổng khối lượng: {+getTotalVolume(route).toFixed(2)}kg
                    </div>
                    <div className="card_text text-end">
                      Tổng thời gian: {Math.round(route?.total_time)}'
                    </div>
                  </div>
                  <button
                    type="submit"
                    id="filter-reset"
                    className="btn btn-primary float-end m-2"
                    onClick={() => {
                      viewOnMap(idx);
                    }}
                  >
                    Xem trên bản đồ
                  </button>
                </div>
              </div>
            )}

          </h2>
          <div id={"collapseHead" + idx + "-" + planID} className={idx === 0 ? ("accordion-collapse collapse show") : ("accordion-collapse collapse")} aria-labelledby="headingOne" >
            <div className="accordion-body ">
              <div className="card-body" >
                <table className="card-table table">
                  <thead>
                    <tr>
                      <th scope="col">Trạm dừng</th>
                      <th scope="col" className={'collapse collapseCol' + idx + "-" + planID} id={"collapseCol" + idx + "-" + planID}>
                        Kho
                      </th>
                      <th scope="col">Mã đơn</th>
                      {/* <th scope="col">Người bán hàng</th> */}
                      {/* <th scope="col">Khách hàng</th> */}
                      {/* <th scope="col">Số điện thoại</th> */}
                      <th scope="col">
                        Địa chỉ
                      </th>
                      <th scope="col">Phường</th>
                      <th scope="col">Quận</th>
                      <th scope="col" className={'collapse collapseCol' + idx + "-" + planID} id={"collapseCol" + idx + "-" + planID}>Thành phố</th>
                      {/* <th scope="col">Ngày hẹn giao</th> */}
                      {/* <th scope="col">Giờ hẹn giao</th> */}
                      {/* <th scope="col">Điều khoản</th> */}
                      {/* <th scope="col">Phương thức thanh toán</th> */}
                      {/* <th scope="col">Phí giao</th> */}
                      <th scope="col">Tổng sản lượng</th>
                      {/* <th scope="col">Tổng giá trị</th> */}
                      {/* <th scope="col">Voucher</th> */}
                      {/* <th scope="col">Voucher Note</th> */}
                      <th scope="col" className={'collapse collapseCol' + idx + "-" + planID} id={"collapseCol" + idx + "-" + planID}>
                        Mã địa chỉ
                      </th>
                      <th scope="col" className={'collapse collapseCol' + idx + "-" + planID} id={"collapseCol" + idx + "-" + planID}>
                        Vĩ độ
                      </th>
                      <th scope="col" className={'collapse collapseCol' + idx + "-" + planID} id={"collapseCol" + idx + "-" + planID}>
                        Kinh độ
                      </th>
                      <th scope="col">
                        <button
                          type="button"
                          id={"collapseButton" + idx + "-" + planID}
                          name={"btnn"}
                          className="btn btn-success float-end collaspseButton"
                          data-bs="collapse"
                          aria-expanded="false"
                          data-bs-target={".collapseCol" + idx + "-" + planID}
                          aria-controls={"collapseCol" + idx + "-" + planID}
                          onClick={() => {
                            let ele = document.getElementById('collapseButton' + idx + "-" + planID);
                            if (ele.innerText === "Đầy đủ")
                              ele.innerText = "Thu gọn";
                            else ele.innerText = "Đầy đủ";
                            let collapseCol = document.getElementsByClassName("collapseCol" + idx + "-" + planID);
                            for (var i = 0; i < collapseCol.length; i++) {
                              var bsCollapse = new Collapse(collapseCol[i])
                              bsCollapse.toggle()
                            }
                          }}
                        >
                          Đầy đủ
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {route?.detail?.length > 0 &&
                      route.detail.map((shipment, jdx) => (
                        <tr key={jdx}>
                          <td scope="row">{jdx + 1}</td>
                          <td scope="row" className={'collapse collapseCol' + idx + "-" + planID} id={"collapseCol" + idx + "-" + planID}>
                            {shipment.warehouse}
                          </td>
                          <td scope="row" >{shipment.order_id}</td>
                          {/* <td scope="row">{shipment.sales_man}</td> */}
                          {/* <td scope="row">{shipment.customer}</td> */}
                          {/* <td scope="row">{shipment.phone}</td> */}
                          <td scope="row" >
                            {shipment.address}
                          </td>
                          <td scope="row">{shipment.ward}</td>
                          <td scope="row">{shipment.district}</td>

                          <td scope="row" className={'collapse collapseCol' + idx + "-" + planID} id={"collapseCol" + idx + "-" + planID}>{shipment.city}</td>
                          {/* <td scope="row">{shipment.commitment_date}</td> */}
                          {/* <td scope="row">{shipment.commitment_time}</td> */}
                          {/* <td scope="row">{shipment.condition}</td> */}
                          {/* <td scope="row">{shipment.payment_method}</td> */}
                          {/* <td scope="row">{shipment.shipment_fee}</td> */}
                          <td scope="row">{shipment.volume}</td>
                          {/* <td scope="row">{shipment.amount}</td> */}
                          {/* <td scope="row">{shipment.voucher}</td> */}
                          {/* <td scope="row">{shipment.voucher_note}</td> */}
                          <td scope="row" className={'collapse collapseCol' + idx + "-" + planID} id={"collapseCol" + idx + "-" + planID}>
                            {shipment.id}
                          </td>
                          <td scope="row" className={'collapse collapseCol' + idx + "-" + planID} id={"collapseCol" + idx + "-" + planID}>
                            {shipment.latitude}
                          </td>
                          <td scope="row" className={'collapse collapseCol' + idx + "-" + planID} id={"collapseCol" + idx + "-" + planID}>
                            {shipment.longitude}
                          </td>
                          {idx === 0 ? (
                            <td scope="row">
                              <button
                                type="submit"
                                id="filter-reset"
                                className="btn btn-info float-end m-2"
                                onClick={() => {
                                  setOldRouteIndex(idx);
                                  setOldIndex(jdx);
                                  setState(!state);
                                }}
                              >
                                Chuyển
                              </button>
                            </td>
                          ) : jdx === 0 ? (
                            <td scope="row"></td>
                          ) : (
                            <td scope="row">
                              <button
                                type="button"
                                id="filter-reset"
                                className="btn btn-warning float-end m-2"
                                onClick={() => {
                                  moveToDraftRoute(idx, jdx);
                                }}
                              >
                                Xóa
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
