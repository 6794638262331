import React from "react";
import "./DisplayNavBar.css";
import DisplayRoute from "../DisplayRoute/DisplayRoute"
export default function DisplayNavBar({
    allPlanning, setAllPlanning, planningID, setPlanningID, baseOrderInfos, routePlanningList, setRoutePlanning, flag
}) {
    return (
        <div>
            {allPlanning.length > 1 ? <h3>Sắp xếp chuyến xe</h3> : <></>}
            <div>
                <ul className="nav nav-tabs">
                    {allPlanning.map((plan, idx) => {
                        if (idx === 0) {
                            return (
                                <li className="nav-item" role="presentation" key={idx}>
                                    <button className="nav-link active"
                                        id={"nav-" + idx + "-tab"}
                                        key={idx}
                                        data-bs-toggle="tab"
                                        data-bs-target={'#nav-' + idx}
                                        type="button"
                                        role="tab"
                                        aria-controls={'nav' + idx}
                                        aria-selected="true"
                                        onClick={() => setPlanningID(idx)}
                                    >Phương án {idx + 1}</button>
                                </li>
                            )
                        }
                        else return (
                            <li className="nav-item" role="presentation" key={idx}>
                                <button className="nav-link"
                                    id={'nav-' + idx + '-tab'}
                                    key={idx}
                                    data-bs-toggle="tab"
                                    data-bs-target={'#nav-' + idx}
                                    type="button"
                                    role="tab"
                                    aria-controls={'nav-' + idx}
                                    aria-selected="false"
                                    onClick={() => setPlanningID(idx)}
                                >Phương án {idx + 1}</button>
                            </li>
                        )
                    })}
                </ul >
                <div className="tab-content" id="nav-tabContent">
                    {allPlanning.length >= 1 && (
                        allPlanning.map((plan, idx) => {
                            if (idx === 0) {
                                return (
                                    <div className="tab-pane fade show active" key={idx} id={'nav-' + idx} role="tabpanel" aria-labelledby={'nav-' + idx + '-tab'}>
                                        < DisplayRoute
                                            key={idx}
                                            allPlanning={allPlanning}
                                            baseOrderInfos={baseOrderInfos}
                                            routePlanningList={routePlanningList}
                                            setOriginRoutePlanning={setRoutePlanning}
                                            index={idx}
                                            flag={flag}
                                        />
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="tab-pane fade" id={'nav-' + idx} key={idx} role="tabpanel" aria-labelledby={'nav-' + idx + '-tab'}>
                                        < DisplayRoute
                                            key={idx}
                                            allPlanning={allPlanning}
                                            baseOrderInfos={baseOrderInfos}
                                            routePlanningList={routePlanningList}
                                            setOriginRoutePlanning={setRoutePlanning}
                                            index={idx}
                                            flag={flag}
                                        />
                                    </div>
                                )
                            }

                        }
                        ))
                    }
                </div>
            </div>
        </div>
    )
}