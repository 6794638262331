import React, { useState, useEffect } from "react";
import DisplayPlan from "../DisplayPlan/DisplayPlan";
import DisplayMap from "../DisplayMap/DisplayMap";
import Modal from "react-modal";
import { Collapse } from "bootstrap";
import { Link, useParams, useLocation } from 'react-router-dom';
import "./ArrangeVehicle.css"
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import { Redirect } from "react-router-dom";

export default function ArrangeVehicle({ }) {
    const { handle } = useParams()
    const location = useLocation()
    const { hi } = location.state["hi"]
    const { ha } = location.state["ha"]
    const P_LOAD = 1000; // ton/man/h
    const P_UNLOAD = 1100; // ton/man/h
    const NUM_LOAD = 4; // man
    const NUM_UNLOAD = 1; // man
    const SKY_TO_VEHICLE_WAY = 1.2;
    const DEGREE_TO_METER = 111139;
    const SERVICE_TIME = 15; // min
    const VEHICLE_SPEED = 40; // km/h
    useEffect(() => {
        console.log(hi)
    }, [handle])

    // useEffect(() => {
    //     if (routePlanningList.length <= index) {
    //         resetState();
    //         setTotalTime(allPlanning[index].total_time);
    //         setTotalLoad(allPlanning[index].total_unload);

    //         let customers = allPlanning[index].customers;
    //         let routes = calculateRoutesMetrics(
    //             customers,
    //             allPlanning[index].route_planning
    //         );
    //         routes = deleteUnusedRoutes(routes);
    //         setRoutePlanning(routePlanning.concat(routes));
    //         updateOriginRouting(routePlanning.concat(routes));
    //         for (var i = 0; i < routes.length; i++) {
    //             reRouting(routes[i].detail);
    //         }
    //         recalculateAllRouteMetric(routePlanning.concat(routes));
    //     } else {
    //         setRoutePlanning(routePlanningList[index])
    //         for (i = 0; i < routePlanningList[index].length; i++) {
    //             reRouting(routePlanningList[index][i].detail);
    //         }
    //         recalculateAllRouteMetric(routePlanningList[index]);
    //     }
    // }, [flag])

    // const deleteUnusedRoutes = (routes) => {
    //     for (var i = 1; i < routes.length; i++) {

    //         if (routes[i].load === 0 || routes[i].detail.length <= 1) {
    //             routes.splice(i, 1)
    //             i -= 1
    //         }
    //     }
    //     return routes
    // }

    // const updateOriginRouting = (routePlan) => {
    //     let list = [...routePlanningList]
    //     let route = { ...list[index] }
    //     route = routePlan
    //     list[index] = route
    //     setOriginRoutePlanning(list)
    //     return;
    // }

    // const [formData, setFormData] = useState({
    //     // formData for call API get_routes
    //     warehouse: "HCM",
    //     vehicle_cap_config: "900",
    //     route_time: "200",
    //     p_load: P_LOAD,
    //     p_unload: P_UNLOAD,
    //     num_load: NUM_LOAD,
    //     num_unload: NUM_UNLOAD,
    //     sky_to_vehicle_way: SKY_TO_VEHICLE_WAY,
    //     degree_to_meter: DEGREE_TO_METER,
    //     service_time: SERVICE_TIME,
    //     vehicle_speed: VEHICLE_SPEED,
    // });

    // const [routePlanning, setRoutePlanning] = useState([
    //     { detail: [], vehicle_id: -1 },
    // ]); // response.route_planning
    // const [totalTime, setTotalTime] = useState(0); // response.total_time
    // const [totalLoad, setTotalLoad] = useState(0); // response.total_unload
    // const [mapRouteIndex, setMapRouteIndex] = useState(0);
    // const [totalVehicleCapacity, setTotalVehicleCapacity] = useState(0);

    // const resetState = () => {
    //     setTotalLoad(0);
    //     setTotalTime(0);
    //     // setResultRoutes([]);
    // };

    // const calculateRoutesMetrics = (orderInfos, routes) => {
    //     let resultRoutes = [];
    //     routes.forEach((route) => {
    //         let resultRoute = calculateRouteMetrics(orderInfos, route);
    //         resultRoutes.push(resultRoute);
    //     });

    //     resultRoutes = resultRoutes.sort((a, b) => {
    //         if (a.total_time === b.total_time) return b.load - a.load;
    //         return b.total_time - a.total_time;
    //     });

    //     return resultRoutes;
    // };

    // function calculateRouteMetrics(orderInfos, route) {
    //     let resultRoute = {
    //         detail: [],
    //         load: 0,
    //         load_time: 0,
    //         unload: 0,
    //         unload_time: 0,
    //         distance: 0,
    //         moving_time: 0,
    //         service_time: 0,
    //         total_time: 0,
    //     };
    //     for (var i = 1; i < route.detail.length; i++) {
    //         let resultShipment = {
    //             ...orderInfos[route.detail[i]],
    //             total_time: 0,
    //         };
    //         updateLoadTime(resultShipment, 0);
    //         updateUnloadTime(resultShipment, resultShipment.volume);
    //         updateDistance(
    //             resultShipment,
    //             distance(orderInfos[route.detail[i - 1]], orderInfos[route.detail[i]])
    //         );
    //         updateService(resultShipment, formData.service_time);
    //         updateOtherInfos(resultShipment, baseOrderInfos);
    //         resultRoute.detail.push(resultShipment);
    //         resultRoute.load += resultShipment.load;
    //         resultRoute.load_time += resultShipment.load_time;
    //         resultRoute.unload += resultShipment.unload;
    //         resultRoute.unload_time += resultShipment.unload_time;
    //         resultRoute.distance += resultShipment.distance;
    //         resultRoute.moving_time += resultShipment.moving_time;
    //         resultRoute.service_time += resultShipment.service_time;
    //         resultRoute.total_time += resultShipment.total_time;
    //     }

    //     let firstShipment = {
    //         ...orderInfos[route.detail[0]],
    //         total_time: 0,
    //     };
    //     updateLoadTime(firstShipment, resultRoute.unload);
    //     updateUnloadTime(firstShipment, 0);
    //     updateDistance(firstShipment, 0);
    //     updateService(firstShipment, 0);
    //     resultRoute.detail.unshift(firstShipment);
    //     resultRoute.load += firstShipment.load;
    //     resultRoute.load_time += firstShipment.load_time;
    //     resultRoute.unload += firstShipment.unload;
    //     resultRoute.unload_time += firstShipment.unload_time;
    //     resultRoute.distance += firstShipment.distance;
    //     resultRoute.moving_time += firstShipment.moving_time;
    //     resultRoute.service_time += firstShipment.service_time;
    //     resultRoute.total_time += firstShipment.total_time;
    //     return resultRoute;
    // }

    // const updateLoadTime = (shipment, volume) => {
    //     shipment["load"] = volume;
    //     shipment["load_time"] = (volume / formData.p_load / formData.num_load) * 60;
    //     shipment.total_time += shipment.load_time;
    // };
    // const updateUnloadTime = (shipment, volume) => {
    //     shipment["unload"] = volume;
    //     shipment["unload_time"] =
    //         (volume / formData.p_unload / formData.num_unload) * 60;
    //     shipment.total_time += shipment.unload_time;
    // };
    // const updateDistance = (shipment, distance) => {
    //     shipment["distance"] = distance;
    //     shipment["moving_time"] =
    //         ((distance * SKY_TO_VEHICLE_WAY) / 1000 / formData.vehicle_speed) * 60;
    //     shipment.total_time += shipment.moving_time;
    // };
    // const updateService = (shipment, time) => {
    //     shipment["service_time"] = time;
    //     shipment.total_time += shipment.service_time;
    // };
    // const updateOtherInfos = (shipment, infos) => {
    //     infos.forEach((info) => {
    //         if (shipment["order_id"] === info["order_id"]) {
    //             shipment["warehouse"] = info["warehouse"];
    //             shipment["address"] = info["address"];
    //             shipment["ward"] = info["ward"];
    //             shipment["district"] = info["district"];
    //             shipment["city"] = info["city"];
    //             shipment["voucher_note"] = info["voucher_note"];
    //             shipment["sales_man"] = info["sales_man"];
    //             shipment["customer"] = info["customer"];
    //             shipment["phone"] = info["phone"];
    //             shipment["commitment_date"] = info["commitment_date"];
    //             shipment["commitment_time"] = info["commitment_time"];
    //             shipment["condition"] = info["condition"];
    //             shipment["payment_method"] = info["payment_method"];
    //             shipment["shipment_fee"] = info["shipment_fee"];

    //             shipment["amount"] =
    //                 Math.round(
    //                     (info["amount"] * shipment["volume"]) / info["volume"] / 100
    //                 ) * 100;
    //             shipment["voucher"] =
    //                 Math.round(
    //                     (info["voucher"] * shipment["volume"]) / info["volume"] / 100
    //                 ) * 100;
    //         }
    //     });
    // };
    // const distance = (a, b) => {
    //     return (
    //         Math.sqrt(
    //             Math.pow(a.latitude - b.latitude, 2) +
    //             Math.pow(a.longitude - b.longitude, 2)
    //         ) * DEGREE_TO_METER
    //     );
    // };

    // const reRouting = (route) => {
    //     const len = route.length;
    //     for (let i = 0; i < len - 1; i++) {
    //         let min = distance(route[i], route[i + 1]);
    //         let min_idx = i + 1;
    //         for (let j = i + 2; j < len; j++) {
    //             if (distance(route[i], route[j]) < min) {
    //                 min = distance(route[i], route[j]);
    //                 min_idx = j;
    //             }
    //         }
    //         {
    //             if (min_idx !== i + 1) {
    //                 let temp = route[min_idx];
    //                 route[min_idx] = route[i + 1];
    //                 route[i + 1] = temp;
    //             }
    //         }
    //     }
    // };

    // const moveToDraftRoute = async (routeIndex, index) => {
    //     await setRoutePlanning((prev) => {
    //         let arr = [...prev];
    //         let o = arr[routeIndex].detail.splice(index, 1);
    //         arr[0].detail = arr[0].detail.concat(o);
    //         // remove NULL route
    //         if (arr[routeIndex].detail.length <= 1) {
    //             arr.splice(routeIndex, 1);
    //         }
    //         recalculateRoutesMetrics(arr);
    //         recalculateAllRouteMetric(arr);
    //         return arr;
    //     });
    //     let ele = document.getElementById('collapseButton0-0')
    //     if (ele.innerText === "Đầy đủ") {
    //         var collapseCol = document.getElementsByClassName("collapseCol0-" + index)
    //         for (var i = 0; i < collapseCol.length; i++) {
    //             var bsCollapse = new Collapse(collapseCol[i], { toggle: false });
    //             bsCollapse.hide()
    //         }
    //     }
    //     else {
    //         let collapseCol = document.getElementsByClassName("collapseCol0-" + index)
    //         for (i = 0; i < collapseCol.length; i++) {
    //             bsCollapse = new Collapse(collapseCol[i], { toggle: false })
    //             bsCollapse.show()
    //         }
    //     }
    //     updateOriginRouting(routePlanning)
    // };
    // const recalculateRoutesMetrics = (routes) => {
    //     routes.forEach((route, index) => {
    //         if (index === 0) return;
    //         route.load = 0;
    //         route.load_time = 0;
    //         route.unload = 0;
    //         route.unload_time = 0;
    //         route.distance = 0;
    //         route.moving_time = 0;
    //         route.service_time = 0;
    //         route.total_time = 0;
    //         for (var i = 1; i < route.detail.length; i++) {
    //             route.detail[i].total_time = 0;
    //             updateLoadTime(route.detail[i], 0);
    //             updateUnloadTime(route.detail[i], route.detail[i].volume);
    //             updateDistance(
    //                 route.detail[i],
    //                 distance(route.detail[i - 1], route.detail[i])
    //             );
    //             updateService(route.detail[i], formData.service_time);
    //             route.load += route.detail[i].load;
    //             route.load_time += route.detail[i].load_time;
    //             route.unload += route.detail[i].unload;
    //             route.unload_time += route.detail[i].unload_time;
    //             route.distance += route.detail[i].distance;
    //             route.moving_time += route.detail[i].moving_time;
    //             route.service_time += route.detail[i].service_time;
    //             route.total_time += route.detail[i].total_time;
    //         }
    //         route.detail[0].total_time = 0;
    //         updateLoadTime(route.detail[0], route.unload);
    //         updateUnloadTime(route.detail[0], 0);
    //         updateDistance(route.detail[0], 0);
    //         updateService(route.detail[0], 0);
    //         route.load += route.detail[0].load;
    //         route.load_time += route.detail[0].load_time;
    //         route.unload += route.detail[0].unload;
    //         route.unload_time += route.detail[0].unload_time;
    //         route.distance += route.detail[0].distance;
    //         route.moving_time += route.detail[0].moving_time;
    //         route.service_time += route.detail[0].service_time;
    //         route.total_time += route.detail[0].total_time;
    //     });
    // };

    // const recalculateAllRouteMetric = (routes) => {
    //     let newVolume = 0;
    //     let newTotalTime = 0;
    //     for (var i = 1; i < routes.length; i++) {
    //         newVolume += routes[i].unload;
    //         newTotalTime += routes[i].total_time;
    //     }

    //     setTotalLoad(newVolume);
    //     setTotalTime(newTotalTime);
    //     getTotalVehicleCapacity(routes);
    //     return;
    // };

    // const moveToSpecificRoute = async (oldRouteIndex, oldIndex, newRouteIndex) => {
    //     await setRoutePlanning((prev) => {
    //         let arr = [...prev];
    //         let o = arr[oldRouteIndex].detail.splice(oldIndex, 1);
    //         arr[newRouteIndex].detail.splice(1, 0, o[0]);
    //         // remove NULL route
    //         if (arr[oldRouteIndex].detail.length <= 2 && oldRouteIndex !== 0) {
    //             arr.splice(oldRouteIndex, 1);
    //         }

    //         reRouting(arr[newRouteIndex].detail);
    //         recalculateRoutesMetrics(arr);
    //         recalculateAllRouteMetric(arr);
    //         return arr;
    //     });
    //     let ele = document.getElementById('collapseButton' + newRouteIndex + '-' + index)
    //     if (ele.innerText === "Đầy đủ") {
    //         var collapseCol = document.getElementsByClassName("collapseCol" + newRouteIndex + "-" + index)
    //         for (var i = 0; i < collapseCol.length; i++) {
    //             var bsCollapse = new Collapse(collapseCol[i], { toggle: false });
    //             bsCollapse.hide()
    //         }
    //     }
    //     else {
    //         let collapseCol = document.getElementsByClassName("collapseCol" + newRouteIndex + "-" + index)
    //         for (i = 0; i < collapseCol.length; i++) {
    //             bsCollapse = new Collapse(collapseCol[i], { toggle: false })
    //             bsCollapse.show()
    //         }
    //     }
    //     updateOriginRouting(routePlanning)
    // };

    // const viewOnMap = (routeIndex) => {
    //     setMapRouteIndex(routeIndex);
    // };

    // const getTotalVehicleCapacity = (route) => {
    //     let capacityList = formData.vehicle_cap_config.split(",");
    //     let a = [];
    //     for (let i = 0; i < capacityList.length; i++) {
    //         if (i % 2 === 0) {
    //             a.push(Number(capacityList[i]));
    //         }
    //     }
    //     if (route) {
    //         let sum = 0;
    //         for (var i = 1; i < route.length; i++) {
    //             let temp = a[0];
    //             for (var j = 0; j < a.length; j++) {
    //                 if (route[i].unload < a[j] && a[j] < temp) temp = a[j];
    //             }
    //             sum += temp;
    //         }
    //         setTotalVehicleCapacity(sum);
    //         return sum;
    //     }
    // };

    // const getNumberOfOrders = () => {
    //     let num = 0;
    //     for (let i = 1; i < routePlanning.length; i++) {
    //         num += (routePlanning[i].detail.length - 1)
    //     }

    //     return num
    // }
    // const getNumberOfRoutes = () => {
    //     let num = 0;
    //     for (let i = 1; i < routePlanning.length; i++) {
    //         if (routePlanning[i].detail.length > 1) num += 1
    //     }
    //     return num
    // }
    return (
        <div>
            <h3>{hi}</h3>
            <h3>{ha}</h3>
        </div>
        // <div id="pages" className="container">
        //     <div className="row mb-2" >
        //         <div className="row mb-2" id="allRouteInfo">
        //             <div className="row">
        //                 {routePlanning.length > 1 && (
        //                     <div className="col mb-2" >
        //                         <h4>Tổng số đơn đã phân: {Math.round(getNumberOfOrders())} đơn</h4>
        //                     </div>
        //                 )}

        //                 {routePlanning.length > 1 && (
        //                     <div className="col mb-2">
        //                         <h4>Tổng số chuyến : {Math.round(getNumberOfRoutes())} chuyến</h4>
        //                     </div>
        //                 )}
        //             </div>
        //             <div className="row">
        //                 {totalLoad > 0 && (
        //                     <div className="col mb-2">
        //                         <h4>
        //                             Tỉ lệ lấp đầy:{" "}
        //                             {Math.round((totalLoad / totalVehicleCapacity) * 100)}%
        //                         </h4>
        //                     </div>
        //                 )}
        //                 {totalTime > 0 && (
        //                     <div className="col mb-2 " >
        //                         <h4>Tổng thời gian: {Math.round(totalTime)} phút</h4>
        //                     </div>
        //                 )}
        //             </div>
        //             <div>
        //                 <Link
        //                     to={{
        //                         pathname: "/vehicle",
        //                         querry: {
        //                             idx: { index },
        //                         }
        //                     }}
        //                     className="btn btn-primary float-end mb-1"
        //                     id="arrangeVehicle"
        //                 >Sắp xếp xe</Link>
        //             </div>
        //         </div>
        //         <div className="row mb-2">
        //             {routePlanning &&
        //                 routePlanning.map((planning, idx) => {
        //                     return (
        //                         <DisplayPlan
        //                             key={idx}
        //                             idx={idx}
        //                             route={planning}
        //                             moveToDraftRoute={moveToDraftRoute}
        //                             moveToSpecificRoute={moveToSpecificRoute}
        //                             viewOnMap={viewOnMap}
        //                             planID={index}
        //                         />
        //                     );
        //                 })}
        //         </div>
        //     </div>

        //     <Modal
        //         isOpen={mapRouteIndex > 0}
        //         // onAfterOpen={afterOpenModal}
        //         onRequestClose={() => {
        //             setMapRouteIndex(0);
        //         }}
        //         ariaHideApp={false}
        //         // style={customStyles}
        //         contentLabel="Example Modal"
        //     >
        //         <div style={{ height: "50vh", width: "100%%" }}>
        //             <div
        //                 style={{
        //                     border: "2px solid rgba(0,0,0,0.2)",
        //                     position: "absolute",
        //                     top: "90px",
        //                     left: "31px",
        //                     zIndex: "801",
        //                     backgroundColor: "#fff",
        //                     borderRadius: "4px",
        //                 }}
        //             >
        //                 <button
        //                     className="btn btn-light"
        //                     style={{
        //                         width: "30px",
        //                         height: "30px",
        //                         fontSize: "22px",
        //                         textAlign: "center",
        //                         border: "none",
        //                         backgroundColor: "#fff",
        //                         font: "bold 18px 'Lucida Console', Monaco, monospace",
        //                         textIndent: "-2px",
        //                     }}
        //                     onClick={() => {
        //                         setMapRouteIndex(0);
        //                     }}
        //                 >x</button>
        //             </div>

        //             <DisplayMap
        //                 routePlanning={routePlanning}
        //                 routeIndex={mapRouteIndex}
        //             />
        //         </div>
        //     </Modal>
        // </div>
    )
}
