import React, { useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import ReactLoading from 'react-loading';

const WaitingBox = (prop) => {
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    setOpen(prop);
  }, [prop])

  return (
    <div>
      <Dialog id="waitingBox" open={open}>
        < DialogTitle >
          Đang phân tuyến...
        </DialogTitle>
        <ReactLoading type={"spin"} style={{ margin: 'auto', fill: '#69A747', height: '20%', width: '20%' }} />
        <DialogContent>
          <DialogContentText>
            Vui lòng đợi trong giây lát
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div >
  );
}

export default WaitingBox;