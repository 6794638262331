import React, { useState } from "react";
import {
  MapContainer,
  Polyline,
  CircleMarker,
  Popup,
  TileLayer,
} from "react-leaflet";
import "./DisplayMap.css"
import "leaflet/dist/leaflet.css";

export default function DisplayMap({ routePlanning, routeIndex }) {
  const purpleOptions = { color: "purple" };
  const redOptions = { color: "red" };

  const polyline = () => {
    let result = [];
    if (routeIndex === 0) return []
    let route = routePlanning[routeIndex];
    route.detail.forEach((shipment) => {
      if (typeof shipment != "number")
        result.push([shipment.latitude, shipment.longitude]);
    });
    return result;
  };

  return (
    <MapContainer center={[10.8703326, 106.8130268]} zoom={12} >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <CircleMarker
        center={[10.8703326, 106.8130268]}
        pathOptions={purpleOptions}
        radius={20}
      >
        <Popup>DC-KoinaLog-HoChiMinh-ThuDuc</Popup>
      </CircleMarker>
      <Polyline pathOptions={purpleOptions} positions={polyline()} />
    </MapContainer>
  );
}
