import axios from "axios";
import React, { useState, useRef } from "react";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx/xlsx.mjs";
import DisplayError from "../DisplayError/DisplayError";
import DisplayWaitingBox from "../DisplayWaitingBox/DisplayWaitingBox";
import DisplayNavBar from "../DisplayNavBar/DisplayNavBar"
import "./GetInfo.css"
import DisplayPlan from "../DisplayPlan/DisplayPlan";


export default function GetInfo() {
  const P_LOAD = 1000; // ton/man/h
  const P_UNLOAD = 1100; // ton/man/h
  const NUM_LOAD = 4; // man
  const NUM_UNLOAD = 1; // man
  const SKY_TO_VEHICLE_WAY = 1.2;
  const DEGREE_TO_METER = 111139;
  const SERVICE_TIME = 15; // m
  const VEHICLE_SPEED = 40; // km/h
  const HOST = "https://vrp.staging-koinavn.com/";




  const [baseOrderInfos, setBaseOrderInfo] = useState({
    columns: [],
    data: [],
  }); // baseOrderInfos which user input from file

  const [formData, setFormData] = useState({
    // formData for call API get_routes
    warehouse: "HCM",
    vehicle_cap_config: "900",
    route_time: "240",
    zone: "1,5,17",
    p_load: P_LOAD,
    p_unload: P_UNLOAD,
    num_load: NUM_LOAD,
    num_unload: NUM_UNLOAD,
    sky_to_vehicle_way: SKY_TO_VEHICLE_WAY,
    degree_to_meter: DEGREE_TO_METER,
    service_time: SERVICE_TIME,
    vehicle_speed: VEHICLE_SPEED,
  });

  const csvLink = useRef(); // for Download Button
  const [resultRoutes, setResultRoutes] = useState([]); // for Download Button
  const [allPlanning, setAllPlanning] = useState([]); // response planning pass to DisplayRoute
  const [planningID, setPlanningID] = useState(-1); // on-hold plan ID 
  const [routePlanningList, setRoutePlanningList] = useState([]) // list of routePlanning
  const [routePlanning, setRoutePlanning] = useState([
    { detail: [], vehicle_id: -1 },
  ]); // response.route_planning
  const [errorMessage, setErrorMessage] = useState();
  const [displayOrderList, setDisplayOrderList] = useState();
  const [waitingBoxState, setWaitingBoxState] = useState(false);
  const [vehicleList, setVehicleList] = useState([
    { volume: "900", amount: "0" },
  ]);
  let headers = ["id", "order_id", "volume", "latitude", "longitude"]; // TODO
  headers = [
    "warehouse",
    "order_id",
    "sales_man",
    "customer",
    "phone",
    "address",
    "ward",
    "district",
    "city",
    "commitment_date",
    "commitment_time",
    "condition",
    "payment_method",
    "shipment_fee",
    "volume",
    "amount",
    "voucher",
    "voucher_note",
    "id",
    "latitude",
    "longitude",
  ];
  const [flag, setFlag] = useState(true);
  let CSVOutPutHeader = ["routes"].concat(headers); //Header of CSV output

  const createFileToGetRoutes = () => {
    if (routePlanningList.length > 0) {
      if (routePlanningList[planningID][0] != null && routePlanningList[planningID][0].detail != null) {
        const rows = routePlanningList[planningID][0].detail.map((o) => {
          return [
            o.id,
            o.order_id,
            o.ward,
            o.district,
            o.volume,
            o.latitude,
            o.longitude,
          ];
        });
        formData.zone = '1'
        let csvContent =
          "id,order_id,ward,distric,volume,latitude,longitude\n" +
          rows.map((e) => e.join(",")).join("\n");
        return new File([csvContent], "abc.csv", { type: "text/csv" });
      }
    }
    else if (routePlanning[0] != null && routePlanning[0].detail != null) {
      const rows = routePlanning[0].detail.map((o) => {
        return [
          o.id,
          o.order_id,
          o.ward,
          o.district,
          o.volume,
          o.latitude,
          o.longitude,
        ];
      });
      let csvContent =
        "id,order_id,ward,distric,volume,latitude,longitude\n" +
        rows.map((e) => e.join(",")).join("\n");
      return new File([csvContent], "abc.csv", { type: "text/csv" });
    } else {
      return;
    }
  };

  // API get_routes
  const getRoutes = async () => {
    blockScreen();
    resetState();

    getVehicleConfig();
    const _formData = new FormData();
    _formData.append("file", createFileToGetRoutes());
    _formData.append("warehouse", formData.warehouse);
    _formData.append("vehicle_cap_config", formData.vehicle_cap_config);
    _formData.append("zone", formData.zone);
    _formData.append("route_time", formData.route_time);
    _formData.append("p_load", formData.p_load);
    _formData.append("p_unload", formData.p_unload);
    _formData.append("num_load", formData.num_load);
    _formData.append("num_unload", formData.num_unload);
    _formData.append("sky_to_vehicle_way", formData.sky_to_vehicle_way);
    _formData.append("degree_to_meter", formData.degree_to_meter);
    _formData.append("service_time", formData.service_time);
    _formData.append("vehicle_speed", formData.vehicle_speed);
    console.log(_formData.get('zone'))
    await axios
      .post(HOST + "get_routes", _formData)
      .then((res) => {
        let data
        if (typeof (res.data) === "string") {
          data = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
          setErrorMessage("Đơn hàng không có địa chỉ để phân tuyến")
        }
        else { data = res.data }
        let len = data.length;
        if (len === 0) {
          setErrorMessage("Không tìm được tuyến đường phù hợp");
        } else {
          if (planningID === -1) {
            data.map((plan, idx) => {
              setAllPlanning(allPlanning => [...allPlanning, plan[0]]);
            }
            );
            setPlanningID(0);
          } else {
            let customers = data[0][0].customers
            let routes = calculateRoutesMetrics(
              customers,
              data[0][0].route_planning
            );
            routes = deleteUnusedRoutes(routes);
            for (var i = 0; i < routes.length; i++) {
              reRouting(routes[i].detail);
            }
            routePlanningList[planningID][0].detail = [];
            let tempRoute = routePlanningList[planningID].concat(routes)
            let tempRoutePlanningList = routePlanningList
            tempRoutePlanningList[planningID] = tempRoute
            setRoutePlanningList(tempRoutePlanningList)
            if (flag === true) setFlag(false)
            else setFlag(true)
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message, true);
        console.error(error);
      })
      .finally(() => {
        unblockScreen();
      });
  };
  const deleteUnusedRoutes = (routes) => {
    for (var i = 1; i < routes.length; i++) {
      if (routes[i].load === 0 || routes[i].detail.length <= 1) {
        routes.splice(i, 1)
        i -= 1
      }
    }
    return routes
  }
  const calculateRoutesMetrics = (orderInfos, routes) => {
    let resultRoutes = [];
    routes.forEach((route) => {
      let resultRoute = calculateRouteMetrics(orderInfos, route);
      resultRoutes.push(resultRoute);
    });

    resultRoutes = resultRoutes.sort((a, b) => {
      if (a.total_time === b.total_time) return b.load - a.load;
      return b.total_time - a.total_time;
    });

    return resultRoutes;
  };
  function calculateRouteMetrics(orderInfos, route) {
    let resultRoute = {
      detail: [],
      load: 0,
      load_time: 0,
      unload: 0,
      unload_time: 0,
      distance: 0,
      moving_time: 0,
      service_time: 0,
      total_time: 0,
    };
    for (var i = 1; i < route.detail.length; i++) {
      let resultShipment = {
        ...orderInfos[route.detail[i]],
        total_time: 0,
      };
      updateLoadTime(resultShipment, 0);
      updateUnloadTime(resultShipment, resultShipment.volume);
      updateDistance(
        resultShipment,
        distance(orderInfos[route.detail[i - 1]], orderInfos[route.detail[i]])
      );
      updateService(resultShipment, formData.service_time);
      updateOtherInfos(resultShipment, baseOrderInfos);
      resultRoute.detail.push(resultShipment);
      resultRoute.load += resultShipment.load;
      resultRoute.load_time += resultShipment.load_time;
      resultRoute.unload += resultShipment.unload;
      resultRoute.unload_time += resultShipment.unload_time;
      resultRoute.distance += resultShipment.distance;
      resultRoute.moving_time += resultShipment.moving_time;
      resultRoute.service_time += resultShipment.service_time;
      resultRoute.total_time += resultShipment.total_time;
    }

    let firstShipment = {
      ...orderInfos[route.detail[0]],
      total_time: 0,
    };
    updateLoadTime(firstShipment, resultRoute.unload);
    updateUnloadTime(firstShipment, 0);
    updateDistance(firstShipment, 0);
    updateService(firstShipment, 0);
    resultRoute.detail.unshift(firstShipment);
    resultRoute.load += firstShipment.load;
    resultRoute.load_time += firstShipment.load_time;
    resultRoute.unload += firstShipment.unload;
    resultRoute.unload_time += firstShipment.unload_time;
    resultRoute.distance += firstShipment.distance;
    resultRoute.moving_time += firstShipment.moving_time;
    resultRoute.service_time += firstShipment.service_time;
    resultRoute.total_time += firstShipment.total_time;
    return resultRoute;
  }
  const updateLoadTime = (shipment, volume) => {
    shipment["load"] = volume;
    shipment["load_time"] = (volume / formData.p_load / formData.num_load) * 60;
    shipment.total_time += shipment.load_time;
  };
  const updateUnloadTime = (shipment, volume) => {
    shipment["unload"] = volume;
    shipment["unload_time"] =
      (volume / formData.p_unload / formData.num_unload) * 60;
    shipment.total_time += shipment.unload_time;
  };
  const updateDistance = (shipment, distance) => {
    shipment["distance"] = distance;
    shipment["moving_time"] =
      ((distance * SKY_TO_VEHICLE_WAY) / 1000 / formData.vehicle_speed) * 60;
    shipment.total_time += shipment.moving_time;
  };
  const updateService = (shipment, time) => {
    shipment["service_time"] = time;
    shipment.total_time += shipment.service_time;
  };
  const updateOtherInfos = (shipment, infos) => {
    infos.forEach((info) => {
      if (shipment["order_id"] === info["order_id"]) {
        shipment["warehouse"] = info["warehouse"];
        shipment["address"] = info["address"];
        shipment["ward"] = info["ward"];
        shipment["district"] = info["district"];
        shipment["city"] = info["city"];
        shipment["voucher_note"] = info["voucher_note"];
        shipment["sales_man"] = info["sales_man"];
        shipment["customer"] = info["customer"];
        shipment["phone"] = info["phone"];
        shipment["commitment_date"] = info["commitment_date"];
        shipment["commitment_time"] = info["commitment_time"];
        shipment["condition"] = info["condition"];
        shipment["payment_method"] = info["payment_method"];
        shipment["shipment_fee"] = info["shipment_fee"];

        shipment["amount"] =
          Math.round(
            (info["amount"] * shipment["volume"]) / info["volume"] / 100
          ) * 100;
        shipment["voucher"] =
          Math.round(
            (info["voucher"] * shipment["volume"]) / info["volume"] / 100
          ) * 100;
      }
    });
  };
  const distance = (a, b) => {
    return (
      Math.sqrt(
        Math.pow(a.latitude - b.latitude, 2) +
        Math.pow(a.longitude - b.longitude, 2)
      ) * DEGREE_TO_METER
    );
  };
  const reRouting = (route) => {
    const len = route.length;
    for (let i = 0; i < len - 1; i++) {
      let min = distance(route[i], route[i + 1]);
      let min_idx = i + 1;
      for (let j = i + 2; j < len; j++) {
        if (distance(route[i], route[j]) < min) {
          min = distance(route[i], route[j]);
          min_idx = j;
        }
      }
      {
        if (min_idx !== i + 1) {
          let temp = route[min_idx];
          route[min_idx] = route[i + 1];
          route[i + 1] = temp;
        }
      }
    }
  };

  const resetState = () => {
    setDisplayOrderList()
    // setAllPlanning([]); // response planning pass to DisplayRoute
    // setPlanningID(0); // on-hold plan ID 
    // setRoutePlanningList([]) // list of routePlanning
    setRoutePlanning([
      { detail: [], vehicle_id: -1 },
    ]); // response.route_planning
  }

  const blockScreen = () => {
    setWaitingBoxState(true);
  };

  const unblockScreen = () => {
    setWaitingBoxState(false);
  };

  const readMainOrderInfoFromFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: headers, blankrow: false, defval: "", raw: false, rawNumbers: false });
      setDraftRoute(data);
      console.log(data)
    };
    reader.readAsBinaryString(file);
  };

  const setDraftRoute = (data) => {
    resetState()
    const lines = data
    const baseOrderInfos = []; // data in CSV file
    for (let i = 1; i < lines.length; i++) {
      baseOrderInfos.push(lines[i]);
    }
    setBaseOrderInfo(baseOrderInfos);
    let displayOrderInfos = [];
    let invalidOrderInfos = [];
    baseOrderInfos.forEach((o, index) => {
      if (o.id === "") { setErrorMessage("Đơn " & o.oid & " thiếu thông tin!") }
      if (o.id && o.order_id && o.volume) {
        displayOrderInfos.push({ ...o });
      } else {
        invalidOrderInfos.push({ ...o });
        setErrorMessage("Đơn " + (index + 1) + " thiếu thông tin. Không thể đưa vào phân tuyến")
      }
    });
    setDisplayOrderList({ ...displayOrderList, detail: displayOrderInfos });
    setRoutePlanning([
      {
        detail: displayOrderInfos.map((o) => o),
        vehicle_id: -1,
      },
    ]); // set DRAFT route
  };

  // for download purpose
  const getCSV = async () => {
    if (routePlanningList) {
      setResultRoutes([])
      for (var i = 0; i < routePlanningList[planningID].length; i++) {
        for (var j = 0; j < routePlanningList[planningID][i].detail.length; j++) {
          const a = {
            routes: i,
            warehouse: routePlanningList[planningID][i].detail[j].warehouse,
            order_id: routePlanningList[planningID][i].detail[j].order_id,
            sales_man: routePlanningList[planningID][i].detail[j].sales_man,
            customer: routePlanningList[planningID][i].detail[j].customer,
            address: routePlanningList[planningID][i].detail[j].address,
            ward: routePlanningList[planningID][i].detail[j].ward,
            district: routePlanningList[planningID][i].detail[j].district,
            city: routePlanningList[planningID][i].detail[j].city,
            commitment_date: routePlanningList[planningID][i].detail[j].commitment_date,
            commitment_time: routePlanningList[planningID][i].detail[j].commitment_time,
            condition: routePlanningList[planningID][i].detail[j].condition,
            payment_method: routePlanningList[planningID][i].detail[j].payment_method,
            shipment_fee: routePlanningList[planningID][i].detail[j].shipment_fee,
            volume: routePlanningList[planningID][i].detail[j].volume,
            amount: routePlanningList[planningID][i].detail[j].amount,
            voucher: routePlanningList[planningID][i].detail[j].voucher,
            voucher_note: routePlanningList[planningID][i].detail[j].voucher_note,
            id: routePlanningList[planningID][i].detail[j].id,
            latitude: routePlanningList[planningID][i].detail[j].latitude,
            longitude: routePlanningList[planningID][i].detail[j].longitude,
          };
          await setResultRoutes((resultRoutes) => [...resultRoutes, a]);
        }
      }
    }
    csvLink.current.link.click();
  };
  const getDate = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    return date;
  };

  const getVehicleConfig = () => {
    //get string of vehicle config
    let str = "";
    for (let i = 1; i < vehicleList.length; i++) {
      str += vehicleList[i].volume + "," + vehicleList[i].amount + ",";
    }
    str += vehicleList[0].volume;
    setFormData({ ...formData, vehicle_cap_config: str });
  };

  const handleVehicleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...vehicleList];
    list[index][name] = value;
    setVehicleList(list);
    let str = "";
    for (let i = 1; i < list.length; i++) {
      str += list[i].volume + "," + list[i].amount + ",";
    }
    str += list[0].volume;
    setFormData({ ...formData, vehicle_cap_config: str });
  };
  const handleRemoveVehicleClick = (index) => {
    const list = [...vehicleList];
    list.splice(index, 1);
    setVehicleList(list);
    let str = "";
    for (let i = 1; i < list.length; i++) {
      str += list[i].volume + "," + list[i].amount + ",";
    }
    str += list[0].volume;
    setFormData({ ...formData, vehicle_cap_config: str });
  };
  const handleAddVehicleClick = () => {
    const list = [...vehicleList]
    list.push({ volume: "2000", amount: "1" })
    setVehicleList(list);
    let str = "";
    for (let i = 1; i < list.length; i++) {
      str += list[i].volume + "," + list[i].amount + ",";
    }
    str += list[0].volume;
    setFormData({ ...formData, vehicle_cap_config: str });
  };

  const handleChecksChange = () => {
    const all = document.getElementById("flexCheckAll")
    const zone1 = document.getElementById("flexCheck1Zone")
    const zone5 = document.getElementById("flexCheck5Zone")
    const zone17 = document.getElementById("flexCheck17Zone")
    if (all.checked) {
      setFormData({ ...formData, zone: all.value })
    }
    else {
      let zoneString = '';
      if (zone1.checked) {
        zoneString += zone1.value
        zoneString += ','
      }
      if (zone5.checked) {
        zoneString += zone5.value
        zoneString += ','
      }
      if (zone17.checked) {
        zoneString += zone17.value
        zoneString += ','
      }
      zoneString = zoneString.slice(0, -1)
      if (zoneString === "") zoneString = "1,5,17"
      setFormData({ ...formData, zone: zoneString })
    }
  }

  return (
    <div id="pages" className="container">
      <div>
        {DisplayError(errorMessage)}
        {DisplayWaitingBox(waitingBoxState)}
        {/* {DisplayChangeBox()} */}
      </div>

      <div className="row mb-2">
        <form
          id="form"
          onSubmit={(e) => {
            e.preventDefault();
            getRoutes();
          }}
        >
          <div className="form-group row mb-2">
            <label htmlFor="file" className="col-sm-2 col-form-label">
              Tải đơn hàng lên
            </label>
            <div className="col-sm-10">
              <input
                type="file"
                className="form-control"
                id="file"
                accept=".csv, .xlsx"
                placeholder="Chưa có file nào được chọn"
                onChange={readMainOrderInfoFromFile}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <label htmlFor="warehouse" className="col-sm-2 col-form-label">
              Kho
            </label>
            <div className="col-sm-10">
              <select
                className="form-control"
                id="warehouse"
                onChange={(event) => {
                  setFormData({ ...formData, warehouse: event.target.value });
                }}
              >
                <option value="HCM">HCM</option>
                <option value="HN">HN</option>
              </select>
            </div>
          </div>
          {vehicleList.slice(1).map((vehicle, idx) => {
            return (
              <div key={idx}>
                <div className="form-group row mb-2">
                  <label
                    htmlFor="vehicle_cap_config"
                    className="col-sm-2 col-form-label"
                  >
                    Trọng tải
                  </label>
                  <div className="col-sm-4">
                    <select
                      className="form-control"
                      id="vehicle_cap_config"
                      required
                      placeholder="Tải trọng phương tiện"
                      value={vehicle.volume}
                      name="volume"
                      onChange={(event) => {
                        handleVehicleChange(event, idx + 1);
                      }}
                    >
                      <option value="2000">2000</option>
                      <option value="900">900</option>
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      id="vehicle_cap_config"
                      required
                      placeholder="Số phương tiện"
                      name="amount"
                      value={vehicle.amount}
                      onChange={(event) => {
                        handleVehicleChange(event, idx + 1);
                      }}
                    />
                  </div>
                  <div className="btn-box col-sm-2">
                    <button
                      type="button"
                      className="btn btn-warning mb-2"
                      onClick={() => handleRemoveVehicleClick(idx + 1)}
                    >
                      Xóa
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="form-group row mb-2">
            <label
              htmlFor="vehicle_cap_config"
              className="col-sm-2 col-form-label"
            >
              Tải trọng cơ bản
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="vehicle_cap_config"
                required
                placeholder="vehicle_cap_config"
                name="volume"
                value={vehicleList[0].volume}
                onChange={(event) => {
                  handleVehicleChange(event, 0);
                }}
              />
              {
                <button
                  type="button"
                  onClick={handleAddVehicleClick}
                  className="btn btn-primary mb-2 mt-2"
                >
                  Thêm loại xe
                </button>
              }
            </div>
          </div>
          <div className="form-group row mb-2">
            <div>
              <label htmlFor="route_time" className="col-sm-2 col-form-label">
                Zone
              </label>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" value="1,5,17" id="flexCheckAll" onChange={(event) => handleChecksChange(event)} />
                <label className="form-check-label" htmlFor="flexCheckAll">
                  Tất cả
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" value="1" id="flexCheck1Zone" onChange={(event) => handleChecksChange(event)} />
                <label className="form-check-label" htmlFor="flexCheck1Zone">
                  1 zone
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" value="5" id="flexCheck5Zone" onChange={(event) => handleChecksChange(event)} />
                <label className="form-check-label" htmlFor="flexCheck5Zone">
                  5 zone
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" value="17" id="flexCheck17Zone" onChange={(event) => handleChecksChange(event)} />
                <label className="form-check-label" htmlFor="flexCheck17Zone">
                  17 zone
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label htmlFor="route_time" className="col-sm-2 col-form-label">
              Thời gian giao hàng (phút)
            </label>
            <div className="col-sm-10">
              <select
                className="form-control"
                id="warehouse"
                onChange={(event) => {
                  setFormData({ ...formData, route_time: event.target.value });
                }}
              >
                <option value="240">240</option>
                <option value="180">180</option>
              </select>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label htmlFor="p_unload" className="col-sm-2 col-form-label">
              Năng suất xuống hàng (kg/giờ)
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="p_unload"
                required
                placeholder="p_unload"
                value={formData.p_unload}
                onChange={(event) => {
                  setFormData({ ...formData, p_unload: event.target.value });
                }}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <label htmlFor="service_time" className="col-sm-2 col-form-label">
              Thời gian phục vụ cho 1 khách trung bình (phút)
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="service_time"
                required
                placeholder="service_time"
                value={formData.service_time}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    service_time: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <label htmlFor="vehicle_speed" className="col-sm-2 col-form-label">
              Vận tốc trung bình (km/h)
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="vehicle_speed"
                required
                placeholder="vehicle_speed"
                value={formData.vehicle_speed}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    vehicle_speed: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <button
            type="submit"
            id="filter-reset"
            className="btn btn-primary float-end mb-2"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            Phân tuyến
          </button>
          <button type="button" className="btn btn-primary float-right mb-2" id="downloadButton" onClick={getCSV}>
            Tải tuyến
          </button>
          <CSVLink
            data={resultRoutes}
            headers={CSVOutPutHeader}
            filename={"Route_Planning_" + getDate() + ".xlsx"}
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </form>
      </div>
      <div className="row mb-2" >

        {displayOrderList ? (
          <div>

            <DisplayPlan
              idx={0}
              route={displayOrderList}
              orderList
              moveToDraftRoute
              moveToSpecificRoute
              viewOnMap
              planID
            />
          </div>) : (
          <></>
        )}

        <DisplayNavBar
          allPlanning={allPlanning}
          setAllPlanning={setAllPlanning}
          planningID={planningID}
          setPlanningID={setPlanningID}
          baseOrderInfos={baseOrderInfos}
          routePlanningList={routePlanningList}
          setRoutePlanning={setRoutePlanningList}
          flag={flag}
        />
      </div>
    </div>
  );
}
